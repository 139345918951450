<template>
  <section class="about">

    <secondary-nav></secondary-nav>

    <div class="su-body">

      <div class="about-us-section container">
        <div class="row">
          <div class="col-1 void-column"></div>

          <div class="col-11 col-md-10">
            <div class="row">
              <div class="col-12">
                <h1>
                  SHOWUP is the evolution of Camus Entertainment,
                  founded in Italy in 1990 and dedicated to the cruise
                  entertainment industry.
                </h1>
              </div>

              <div class="col-12">
                <div class="about-us-image"></div>
              </div>

              <div class="col-12">
                <h3>
                  Our team has created over 300 shows for international audiences,
                  involving acrobats, dancers, singers, musicians, and performers
                  of every kind. We have enjoyed the privilege of managing the
                  entertainment on MSC's World Cruises, a challenge on a truly
                  global scale requiring the programming of over 94 different
                  shows to be performed all over the planet.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';
import SecondaryNavBar from "@/components/secondary-nav";

export default Vue.extend({
  name: 'about',
  metaInfo: {
    title: 'About',
  },
  components: {
    'secondary-nav' : SecondaryNavBar,
  },
});
</script>
