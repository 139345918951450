<template>
  <section class="shows">
    <secondary-nav></secondary-nav>

    <div class="su-body-shows d-flex justify-content-center">
      <div class="shows-section">
        <div class="container d-flex justify-content-center">
          <div class="col-1 void-column"></div>

          <div class="col-md-11 col-12 row">
            <!-- One Night Only -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="815640386"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>One night only</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Fantasy Woods -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="815641925"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Fantasy Woods</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Nature -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="837591506"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Nature</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Ritmo -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="819561966"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Ritmo</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Total Divas -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="1017132808"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Total Divas</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Live From -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="1017132665"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Live From</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Rock Royalty -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="1017130311"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Rock Royalty</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Road To Nowhere -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="1017130271"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Road To Nowhere</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Picture Perfect -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="1017130231"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Picture Perfect</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Encore -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="1017130086"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Encore</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Club Disco -->
            <div class="col-lg-4 col-12 pl-2 pr-2">
              <div class="marquee-card">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <vimeo-player
                      :video-id="1017130042"
                      :options="{
                        pip: false,
                      }"
                      class="video-player w-auto"
                    ></vimeo-player>
                  </div>
                  <div class="col-12 title-card">
                    <span>Club Disco</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';
import SecondaryNavBar from "@/components/secondary-nav";
import vueVimeoPlayer from 'vue-vimeo-player';

export default Vue.extend({
  name: 'shows',
  metaInfo: {
    title: 'Our Shows',
  },
  components: {
    'secondary-nav' : SecondaryNavBar,
  },
});
Vue.use(vueVimeoPlayer);
</script>
